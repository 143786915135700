import { clearBrowserCache } from '@agdir/core/angular';
import { Company, Device, Field, Profile, Share } from '@agdir/domain';
import { AuthService, CompanyAssetsLoaderService, CompanyService, CUSTOMER_PATHS, SharesService } from '@agdir/services';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { createCriticalServerErrorDialog, resetCriticalServerErrorDialog, useCircuitBreaker } from './critical-server-error-dialog';

export interface CustomerAssets {
	locations?: Field[]; // TODO
	devices?: Device[]; // TODO
	profile?: Profile; // TODO
	company?: Company;
	shares?: Share[];
	companies?: Company[];
	loadError?: any;
}

export const CustomerAssetsResolver: ResolveFn<CustomerAssets> = async (route: ActivatedRouteSnapshot) => {
	const companyService = inject(CompanyService);
	const sharesService = inject(SharesService);
	const assetsLoaderService = inject(CompanyAssetsLoaderService);
	const authService = inject(AuthService);
	const amIPowerUser = await firstValueFrom(authService.amIAgdirPowerUser());
	let intervalHandler: any;
	try {
		const companies = amIPowerUser
			? await firstValueFrom(companyService.getAllAllAllCompanies())
			: await firstValueFrom(sharesService.getMyCompanies().pipe(map((shares) => shares.map((share) => share.company as Company))));
		const shares = await firstValueFrom(sharesService.getMyCompanies());
		const company = companies.find((c) => c.id === route.paramMap?.get('companyId'));
		if (company) {
			companyService.setCurrentCompany(company);
			await firstValueFrom(assetsLoaderService.initAssets());
		}
		resetCriticalServerErrorDialog();
		return {
			companies: companies.sort((c1, c2) => ((c1.organizationName || '') > (c2.organizationName || '') ? 1 : -1)),
			company,
			shares,
		};
	} catch (e: any) {
		console.error(e);
		reloadPage(e);
		if (intervalHandler) {
			clearInterval(intervalHandler);
		}
		intervalHandler = setInterval(() => reloadPage(e), 10000);
		return { loadError: e };
	}

	function reloadPage(e: any) {
		if (window.navigator.onLine) {
			clearInterval(intervalHandler);
			clearBrowserCache();
			useCircuitBreaker(
				() => (window.location.href = CUSTOMER_PATHS.ALL_FARMS),
				() => createCriticalServerErrorDialog(e),
			);
		}
	}
};
