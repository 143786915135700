const AGDIR_CIRCUIT_BREAKER_MAX = 3;
const AGDIR_CIRCUIT_BREAKER = 'agdir-circuit-breaker';

export const useCircuitBreaker = (retryableFn: () => void, retriesReachedFn: () => void) => {
	const retries = parseInt(sessionStorage.getItem(AGDIR_CIRCUIT_BREAKER) || '0');
	if (retries < AGDIR_CIRCUIT_BREAKER_MAX) {
		sessionStorage.setItem(AGDIR_CIRCUIT_BREAKER, (retries + 1).toString());
		retryableFn();
	} else {
		retriesReachedFn();
	}
};
export const resetCriticalServerErrorDialog = () => sessionStorage.removeItem(AGDIR_CIRCUIT_BREAKER);

export const createCriticalServerErrorDialog = (err: any) => {
	const dialog = document.createElement('div');
	dialog.style.fontFamily = 'monospace';
	dialog.style.position = 'fixed';
	dialog.style.top = '50%';
	dialog.style.left = '50%';
	dialog.style.transform = 'translate(-50%, -50%)';
	dialog.style.backgroundColor = 'white';
	dialog.style.padding = '20px';
	dialog.style.display = 'flex';
	dialog.style.flexDirection = 'column';
	dialog.style.gap = '1em';
	dialog.style.border = '1px solid black';
	dialog.style.zIndex = '1000';

	const message = document.createElement('p');
	message.textContent = '[CUSTOMER_ASSETS_RESOLVER]: There seem to be issues with server connection. Please try again or contact us at ';

	const mailLink = document.createElement('a');
	mailLink.href = 'mailto:support@agdir.no';
	mailLink.textContent = 'support@agdir.no';
	const reloadButton = document.createElement('button');
	reloadButton.textContent = 'Reload \u27A1'; // Unicode right arrow
	reloadButton.style.padding = '.5em';
	reloadButton.style.border = '1px solid black';
	reloadButton.style.backgroundColor = 'blue';
	reloadButton.style.color = 'white';
	reloadButton.onclick = () => (window.location.href = '/');
	const tryLoginAgainButton = document.createElement('button');
	tryLoginAgainButton.textContent = 'Sign-In Again \u27A1'; // Unicode right arrow
	tryLoginAgainButton.style.padding = '.5em';
	tryLoginAgainButton.style.border = '1px solid black';
	tryLoginAgainButton.style.backgroundColor = 'blue';
	tryLoginAgainButton.style.color = 'white';
	tryLoginAgainButton.onclick = () => {
		localStorage.clear();
		window.location.href = '/auth/sign-in';
	};
	const moreInfoButton = document.createElement('button');
	moreInfoButton.textContent = 'More info';
	moreInfoButton.style.padding = '.5em';
	moreInfoButton.style.border = '1px solid black';
	moreInfoButton.style.backgroundColor = 'white';
	moreInfoButton.style.color = 'blue';
	moreInfoButton.onclick = () => {
		const errorDetails = document.createElement('pre');
		errorDetails.style.backgroundColor = 'black';
		errorDetails.style.color = 'red';
		errorDetails.style.whiteSpace = 'pre-wrap';
		errorDetails.textContent = JSON.stringify(err, null, 2);
		dialog.appendChild(errorDetails);
	};

	message.appendChild(mailLink);
	dialog.appendChild(message);
	dialog.appendChild(reloadButton);
	dialog.appendChild(tryLoginAgainButton);
	dialog.appendChild(moreInfoButton);
	document.body.appendChild(dialog);
};
